<template>
  <div data-luko-tracking="PopupError">
    <LukoDown v-if="errorData.type === ErrorPopupType.LukoDown" />
    <VpnError v-else-if="errorData.type === ErrorPopupType.VpnError" />
    <Unauthorized v-else-if="errorData.type === ErrorPopupType.Unauthorized" />
    <ComplyAdvantage v-else-if="errorData.type === ErrorPopupType.ComplyAdvantage" />
    <ProspectLocked v-else-if="errorData.type === ErrorPopupType.ProspectLocked" />
    <ProspectSigned v-else-if="errorData.type === ErrorPopupType.ProspectSigned" />
    <ProspectMissingContract
      v-else-if="errorData.type === ErrorPopupType.ProspectMissingContract"
    />
    <PricingMismatch v-else-if="errorData.type === ErrorPopupType.PricingMismatchError" />
    <SubscriptionInThePast
      v-else-if="errorData.type === ErrorPopupType.SubscriptionInThePastError"
    />
    <AlreadySigned v-else-if="errorData.type === ErrorPopupType.AlreadySigned" />
    <CreatedAccount v-else-if="errorData.type === ErrorPopupType.CreatedAccount" />
    <SepaNotAuthorized v-else-if="errorData.type === ErrorPopupType.SepaNotAuthorized" />
    <ValueError v-else-if="errorData.type === ErrorPopupType.ValueError" />
    <MovingError v-else-if="errorData.type === ErrorPopupType.MovingError" />
    <StripeError v-else-if="errorData.type === ErrorPopupType.StripeError" />
    <Retry v-else-if="errorData.type === ErrorPopupType.Retry" />
    <OldError v-else-if="errorData.type === ErrorPopupType._Old" />
    <Unhandled v-else />
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

import { useError } from '@/composables/utils/useError';
import { ErrorPopupType } from '@/composables/utils/useError/useError.types';

const LukoDown = defineAsyncComponent(() => import('./components/LukoDown.vue'));
const OldError = defineAsyncComponent(() => import('./components/OldError.vue'));
const Retry = defineAsyncComponent(() => import('./components/Retry.vue'));
const Unauthorized = defineAsyncComponent(() => import('./components/Unauthorized.vue'));
const Unhandled = defineAsyncComponent(() => import('./components/Unhandled.vue'));
const ProspectLocked = defineAsyncComponent(() => import('./components/ProspectLocked.vue'));
const ProspectSigned = defineAsyncComponent(() => import('./components/ProspectSigned.vue'));
const ProspectMissingContract = defineAsyncComponent(
  () => import('./components/ProspectMissingContract.vue')
);
const ValueError = defineAsyncComponent(() => import('./components/ValueError'));
const ComplyAdvantage = defineAsyncComponent(() => import('./components/ComplyAdvantage.vue'));
const PricingMismatch = defineAsyncComponent(() => import('./components/PricingMismatch.vue'));
const SepaNotAuthorized = defineAsyncComponent(() => import('./components/SepaNotAuthorized.vue'));
const SubscriptionInThePast = defineAsyncComponent(
  () => import('./components/SubscriptionInThePast.vue')
);
const AlreadySigned = defineAsyncComponent(() => import('./components/AlreadySigned.vue'));
const CreatedAccount = defineAsyncComponent(() => import('./components/CreatedAccount.vue'));
const VpnError = defineAsyncComponent(() => import('./components/VpnError.vue'));
const MovingError = defineAsyncComponent(() => import('./components/MovingError.vue'));
const StripeError = defineAsyncComponent(() => import('./components/StripeError.vue'));
const { errorData } = useError();
</script>
